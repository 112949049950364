import type { JWTPayload } from 'jose';
import { extractToken, getCookie } from './modules/cookie';
import { importWithBrand } from './utils/importWithBrand';
import type { Slots as LoginWallOverlaySlots } from './modules/login-wall/LoginWallOverlay.preprocess';
import type { Slots as IdentityWallOverlaySlots } from './modules/identity-wall/IdentityWallOverlay.preprocess';
import type { AnalyticsOptions } from './utils/analytics';

/**
 * A quick note on the types used in this file:
 */

/**
 * The id token is a JWT token that contains information about the user.
 */
export interface IdToken extends JWTPayload {
  isInFirstTimeLoginBrand: boolean;
  'https://mediahuis.com/principal-type': 'USER';
  given_name: string;
  family_name: string;
  nickname: string;
  y;
  name: string;
  picture: string;
  gender: {
    type: 'Male' | 'Female' | 'Other';
  };
  updated_at: string;
  email: string;
  email_verified: boolean;
}

/**
 * The access token is a JWT token that contains access information about the user.
 */
export interface AccessToken extends JWTPayload {
  'https://mediahuis.com/principal-type': 'USER';
  'https://mediahuis.com/subscribed-accesses'?: Array<string>;
  'https://mediahuis.com/identity-levels'?: Array<string>;
  iss: string;
  sub: string;
  aud: Array<string>;
  iat: number;
  exp: number;
  azp: string;
  scope: string;
}

export interface FullIdentity {
  email: string;
  emailVerified: boolean;
  namesPerson: {
    nickName: string | null;
    firstName: string | null;
    lastName: string | null;
  };
  demographicsPerson: {
    genderCode: 'u' | 'm' | 'f' | 'o' | null;
    genderCustom: string | null;
    dateOfBirth: string | null;
  };
  contactpointsPhone: {
    phone: string | null;
  };
  address: {
    street: string | null;
    houseNumber: string | null;
    houseNumberExtension: string | null;
    city: string | null;
    postalCode: string | null;
    countryCode: string | null;
    qualityLabel: string | null;
  };
  identityLevels: Array<string> | null;
}

interface Config {
  env: string;
  brand: string;
  version: string;
}

/**
 * @private
 * @param clientId Auth0 client id
 * @returns {Config}
 */
export function getConfig(clientId: string): Config {
  const cookieValue = getCookie(`auth0_${clientId}_config`);
  if (!cookieValue) {
    throw new Error(`No config found for client id: ${clientId}`);
  }
  const params = new URLSearchParams(cookieValue);

  return (Array.from(params.entries()) as Array<[keyof Config, Config[keyof Config]]>).reduce((acc, [key, value]) => {
    acc[key] = value;

    return acc;
  }, {} as Config);
}

/**
 * Get the access token of a logged in user. Useful when you need to send this token to a backend service from the client.
 *
 * @param clientId Auth0 client id
 * @returns Access token or null
 */
export function getAccessToken(clientId: string) {
  return getCookie(`auth0_${clientId}_acc_token`);
}

/**
 * Returns the access token fully parsed and decoded of a loggedin user.
 *
 * @param clientId Auth0 client id
 * @returns The parsed access token or null
 */
export async function getUserSubscriptions(clientId: string) {
  const decodedAccessToken = await getDecodedAccessToken(clientId);

  return decodedAccessToken?.['https://mediahuis.com/subscribed-accesses'] ?? [];
}

/**
 * Returns the access token fully parsed and decoded of a loggedin user.
 *
 * @param clientId Auth0 client id
 * @returns The parsed access token or null
 */
export async function getDecodedAccessToken(clientId: string): Promise<AccessToken> {
  return extractToken<AccessToken>(`auth0_${clientId}_acc_token`);
}

/**
 * Retrieve the raw id token of a logged in user. If you're looking to extract this infomration you can simply use the `getUserInfo` function instead.
 *
 * @param clientId Auth0 client id
 * @returns Raw JWT id token or null
 */
export function getIdToken(clientId: string) {
  return getCookie(`auth0_${clientId}_id_token`);
}

/**
 * Returns the id token fully parsed and decoded of a loggedin user.
 *
 * @param clientId Auth0 client id
 * @returns The parsed id token or null
 */
export async function getDecodedIdToken(clientId: string): Promise<IdToken> {
  return extractToken<IdToken>(`auth0_${clientId}_id_token`);
}

/**
 * Returns the id token fully parsed and decoded of a loggedin user.
 *
 * @param clientId Auth0 client id
 * @returns The parsed id token or null
 */
export async function getUserInfo(clientId: string): Promise<IdToken> {
  return getDecodedIdToken(clientId);
}

/**
 * Returns the id token fully parsed and decoded of a loggedin user.
 *
 * @param clientId Auth0 client id
 * @returns The parsed id token or null
 */
export async function getFullUserProfile(clientId: string): Promise<FullIdentity | null> {
  if (!isAuthenticated(clientId)) {
    return null;
  }

  const accessToken = await getDecodedAccessToken(clientId);

  const config = getConfig(clientId);
  let domain = `https://identity-management.mediahuis.com`;
  if (config.env === 'test') {
    domain = `https://identity-management-tst.mediahuis.com`;
  } else if (config.env === 'preview' || config.env === 'staging') {
    domain = `https://identity-management-uat.mediahuis.com`;
  } else if (config.env === 'dev') {
    domain = `https://identity-management-dev.mediahuis.com`;
  }
  const url = new URL(`/api/identities/${accessToken.sub}`, domain);
  const response = await fetch(url, {
    headers: {
      authorization: `Bearer ${getAccessToken(clientId)}`,
    },
  });

  if (!response.ok) {
    return null;
  }

  return response.json();
}

/**
 * Is the user authenticated?
 *
 * @param clientId Auth0 client id
 * @returns True if the user is authenticated, false otherwise
 */
export function isAuthenticated(clientId: string): boolean {
  return !!getAccessToken(clientId) && !!getIdToken(clientId);
}

/**
 * Is the email verified?
 *
 * @param clientId Auth0 client id
 * @returns True if the email is verified, false otherwise
 */
export async function isEmailVerified(clientId: string): Promise<boolean> {
  const userInfo = await getUserInfo(clientId);

  if (!userInfo) {
    return false;
  }

  return userInfo.email_verified;
}

export async function hasIdentityLevel(clientId: string, identityLevel: string): Promise<boolean> {
  const accessToken = await getDecodedAccessToken(clientId);

  return accessToken['https://mediahuis.com/identity-levels']?.includes(identityLevel) ?? false;
}

export async function sendEmailVerification(clientId: string): Promise<boolean> {
  const userInfo = await getDecodedIdToken(clientId);
  if (userInfo.email_verified) {
    return false;
  }

  const verifyEmailUrl = new URL('/auth/verify-email', window.location.origin);
  verifyEmailUrl.searchParams.append('accountId', userInfo.sub);

  try {
    const resp = await fetch(verifyEmailUrl, {
      headers: {
        authorization: 'Bearer ' + getAccessToken(clientId),
      },
    });

    return resp.ok;
  } catch (err) {
    return false;
  }
}

/**
 * When not loggedin show login wall.
 * showLoginWall let you put our shared component based on the Mediahuis design system in your page.
 *
 * @param options The list of options to pass to the popup
 * @param options.type The type of email confirmation to show
 * @param options.loginIdentityLevel The identity level to login
 * @param options.registerIdentityLevel The identity level to register
 * @param options.el The DOM element to render the email confirmation in
 * @param options.slots.title Custom title for login wall
 * @param options.slots.content Custom content for login wall
 * @param options.slots.callToAction Custom call to action for login wall
 * @param options.onTrackEvent Function to track events
 * @returns
 */
export async function showLoginWall({
  type,
  clientId,
  loginIdentityLevel,
  registerIdentityLevel,
  el,
  slots = {},
  options,
  onTrackEvent,
}: {
  type: 'paywall' | 'inline' | 'overlay';
  clientId: string;
  loginIdentityLevel: string;
  registerIdentityLevel: string;
  el: HTMLElement;
  slots?: Partial<LoginWallOverlaySlots>;
  options: Partial<AnalyticsOptions>;
  onTrackEvent: (event: any) => void;
}): Promise<void> {
  if (!['paywall', 'inline', 'overlay'].includes(type)) {
    throw new Error(`Unsupported type: ${type}, only paywall, inline or overlay is currently implemented.`);
  }

  if (!el) {
    throw new Error(`No element provided to render the identity wall in.`);
  }

  if (isAuthenticated(clientId)) {
    throw new Error(`User is authenticated.`);
  }

  const config = getConfig(clientId);

  if (type === 'overlay') {
    const { appendLoginWall } = await importWithBrand('./modules/login-wall/LoginWallOverlay', config.brand);

    return appendLoginWall(el, {
      requiredLoginIdentityLevel: loginIdentityLevel,
      requiredRegisterIdentityLevel: registerIdentityLevel,
      brand: config.brand,
      slots,
      onTrackEvent,
      options,
    });
  } else {
    const { appendLoginWall } = await importWithBrand('./modules/login-wall/LoginWall', config.brand);

    return appendLoginWall(el, {
      requiredLoginIdentityLevel: loginIdentityLevel,
      requiredRegisterIdentityLevel: registerIdentityLevel,
      brand: config.brand,
      clientId,
      slots,
      onTrackEvent,
      options,
    });
  }
}

/**
 * Some brands require a specific identity level before reading any metered or plus articles.
 * showIdentityWall let you put our shared component based on the Mediahuis design system in your page.
 *
 * @param options The list of options to pass to the popup
 * @param options.clientId Auth0 client id
 * @param options.type The type of email confirmation to show
 * @param options.requiredIdentityLevel The identity level to progress
 * @param options.el The DOM element to render the email confirmation in
 * @param options.slots.title Custom title for login wall
 * @param options.slots.content Custom content for login wall
 * @param options.slots.callToAction Custom call to action for login wall
 * @param options.onTrackEvent Function to track events
 * @param options.options["ext-internal"] Internal tracking parameter
 * @returns
 */
export async function showIdentityWall({
  type,
  clientId,
  identityLevel,
  el,
  slots = {},
  onTrackEvent,
  options,
}: {
  type: 'paywall' | 'overlay' | 'inline';
  clientId: string;
  identityLevel: string;
  el: HTMLElement;
  slots?: Partial<IdentityWallOverlaySlots>;
  onTrackEvent: (event: Event) => void;
  options: Partial<AnalyticsOptions>;
}): Promise<void> {
  // if (type !== 'paywall') {
  // 	throw new Error(`Unsupported type: ${type}, only paywall is currently implemented.`);
  // }

  if (!el) {
    throw new Error(`No element provided to render the identity wall in.`);
  }

  if (!isAuthenticated(clientId)) {
    throw new Error(`User is not authenticated.`);
  }

  const config = getConfig(clientId);

  if (type === 'overlay') {
    const { appendIdentityWall } = await importWithBrand('./modules/identity-wall/IdentityWallOverlay', config.brand);

    return appendIdentityWall(el, {
      requiredIdentityLevel: identityLevel,
      brand: config.brand,
      clientId,
      slots,
      onTrackEvent,
      options,
    });
  } else {
    const { appendIdentityWall } = await importWithBrand('./modules/identity-wall/IdentityWall', config.brand);

    return appendIdentityWall(el, {
      requiredIdentityLevel: identityLevel,
      brand: config.brand,
      clientId,
      slots,
      onTrackEvent,
      options,
    });
  }
}

/**
 * Some brands require a specific identity to be show  before reading any metered or plus articles.
 *
 * @param options The list of options to pass to the popup
 * @param options.clientId Auth0 client id
 * @param options.type The type of email confirmation to show
 * @param options.el The DOM element to render the email confirmation in
 * @param options.onTrackEvent Function to track events
 * @returns
 */
export async function showEmailConfirmation({
  type,
  clientId,
  el,
  onTrackEvent,
}: {
  type: 'paywall' | 'inline';
  clientId: string;
  el: HTMLElement;
  onTrackEvent: (event: Event) => void;
}) {
  // if (type !== 'paywall') {
  // 	throw new Error(`Unsupported type: ${type}, only paywall is currently implemented.`);
  // }

  if (!isAuthenticated(clientId)) {
    throw new Error(`User is not authenticated.`);
  }

  if (!el) {
    throw new Error(`No element provided to render the email confirmation box in.`);
  }

  const userInfo = await getDecodedIdToken(clientId);

  if (userInfo.email_verified) {
    throw new Error(`User has already been verified.`);
  }

  const config = getConfig(clientId);

  const { appendEmailVerificationWall } = await importWithBrand('./modules/email-confirmation/EmailConfirmationWall', config.brand);
  return appendEmailVerificationWall(el, { email: userInfo.email, brand: config.brand, clientId, onTrackEvent });
}

/**
 * If you want to control when to show the silent login popup.
 *
 * Note that we're using the Mediahuis design system to create these popups.
 *
 * @param options The list of options to pass to the popup
 * @param options.clientId Auth0 client id
 * @param options.el The DOM element to render the silent login notification in
 * @returns
 */
export async function showSilentLoginNotification({ clientId, el }: { clientId: string; el: HTMLElement }): Promise<void> {
  const parsedUrl = new URL(window.location.href);
  parsedUrl.searchParams.delete('_sl');
  window.history.replaceState(history.state, undefined, parsedUrl.toString());
  const userInfo = await getUserInfo(clientId);

  if (!userInfo.email) {
    return;
  }

  if (!el) {
    throw new Error(`No element provided to render the silent login toast in.`);
  }

  const { appendNotificationToast } = await import('./modules/silent-login/NotificationToast.mh');

  Object.assign(el.style, {
    position: 'fixed',
    zIndex: '9999',
    top: 0,
    right: 0,
  });

  const config = getConfig(clientId);
  await appendNotificationToast(el, { email: userInfo.email, brand: config.brand });

  return new Promise((resolve) => {
    requestAnimationFrame(() => {
      resolve();
    });
  });
}
